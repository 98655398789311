$wraps: wrap no-wrap;

@each $wrap in $wraps {
  .#{$wrap} {
    flex-wrap: $wrap;
  }
}

$justifies: center end start space-between space-around space-evenly;

@each $justify in $justifies {
  .justify-#{$justify} {
    justify-content: $justify;
  }

  @each $modifier ,
  $breakpoint in $map-viewports {
    @if($modifier =='') {
      $modifier: '-xs';
    }

    @include create-mq($breakpoint, 'min') {
      .justify#{$modifier}-#{$justify} {
        justify-content: $justify !important;
      }
    }
  }
}

$aligns: start center end;

@each $align in $aligns {
  .align-#{$align} {
    align-items: $align;
  }
}

$directions: column row;

@each $direction in $directions {
  .direction-#{$direction} {
    flex-direction: $direction;
  }
}

@each $modifier ,
$breakpoint in $map-viewports {
  @if($modifier =='') {
    $modifier: '-xs';
  }

  @include create-mq($breakpoint, 'min') {
    .direction#{$modifier}-col {
      flex-direction: column !important;
    }
    .direction#{$modifier}-row {
      flex-direction: row !important;
    }
  }

}