/*** Utilities ***/
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower:wght@400&display=swap");
.hidden-xs-down {
  display: none !important;
}

.hidden-xs-up {
  display: none !important;
}

@media screen and (max-width: 312px) {
  .hidden-sm-down {
    display: none !important;
  }
}
@media screen and (min-width: 312px) {
  .hidden-sm-up {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .hidden-md-down {
    display: none !important;
  }
}
@media screen and (min-width: 767px) {
  .hidden-md-up {
    display: none !important;
  }
}
@media screen and (max-width: 992px) {
  .hidden-lg-down {
    display: none !important;
  }
}
@media screen and (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}
@media screen and (max-width: 1440px) {
  .hidden-xl-down {
    display: none !important;
  }
}
@media screen and (min-width: 1440px) {
  .hidden-xl-up {
    display: none !important;
  }
}
.p-auto {
  padding: auto;
}

.p-x-auto {
  padding-left: auto;
  padding-right: auto;
}

.p-y-auto {
  padding-top: auto;
  padding-bottom: auto;
}

.padding-auto {
  padding: auto !important;
}

.padding-y-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.padding-x-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-auto {
    padding: auto !important;
  }
  .padding-y-sm-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .padding-x-sm-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-auto {
    padding: auto !important;
  }
  .padding-y-md-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .padding-x-md-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-auto {
    padding: auto !important;
  }
  .padding-y-lg-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .padding-x-lg-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-auto {
    padding: auto !important;
  }
  .padding-y-xl-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .padding-x-xl-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
}
.p-3 {
  padding: 3px;
}

.p-x-3 {
  padding-left: 3px;
  padding-right: 3px;
}

.p-y-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.padding-3 {
  padding: 3px !important;
}

.padding-y-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.padding-x-3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-3 {
    padding: 3px !important;
  }
  .padding-y-sm-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .padding-x-sm-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-3 {
    padding: 3px !important;
  }
  .padding-y-md-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .padding-x-md-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-3 {
    padding: 3px !important;
  }
  .padding-y-lg-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .padding-x-lg-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-3 {
    padding: 3px !important;
  }
  .padding-y-xl-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .padding-x-xl-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}
.p-5 {
  padding: 5px;
}

.p-x-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-y-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-5 {
  padding: 5px !important;
}

.padding-y-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.padding-x-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-5 {
    padding: 5px !important;
  }
  .padding-y-sm-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .padding-x-sm-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-5 {
    padding: 5px !important;
  }
  .padding-y-md-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .padding-x-md-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-5 {
    padding: 5px !important;
  }
  .padding-y-lg-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .padding-x-lg-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-5 {
    padding: 5px !important;
  }
  .padding-y-xl-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .padding-x-xl-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
.p-20 {
  padding: 20px;
}

.p-x-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-y-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-20 {
  padding: 20px !important;
}

.padding-y-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.padding-x-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-20 {
    padding: 20px !important;
  }
  .padding-y-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .padding-x-sm-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-20 {
    padding: 20px !important;
  }
  .padding-y-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .padding-x-md-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-20 {
    padding: 20px !important;
  }
  .padding-y-lg-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .padding-x-lg-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-20 {
    padding: 20px !important;
  }
  .padding-y-xl-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .padding-x-xl-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.p-25 {
  padding: 25px;
}

.p-x-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.p-y-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.padding-25 {
  padding: 25px !important;
}

.padding-y-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.padding-x-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-25 {
    padding: 25px !important;
  }
  .padding-y-sm-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .padding-x-sm-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-25 {
    padding: 25px !important;
  }
  .padding-y-md-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .padding-x-md-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-25 {
    padding: 25px !important;
  }
  .padding-y-lg-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .padding-x-lg-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-25 {
    padding: 25px !important;
  }
  .padding-y-xl-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .padding-x-xl-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}
.p-30 {
  padding: 30px;
}

.p-x-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-y-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-30 {
  padding: 30px !important;
}

.padding-y-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.padding-x-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-30 {
    padding: 30px !important;
  }
  .padding-y-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .padding-x-sm-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-30 {
    padding: 30px !important;
  }
  .padding-y-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .padding-x-md-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-30 {
    padding: 30px !important;
  }
  .padding-y-lg-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .padding-x-lg-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-30 {
    padding: 30px !important;
  }
  .padding-y-xl-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .padding-x-xl-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
.p-35 {
  padding: 35px;
}

.p-x-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.p-y-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.padding-35 {
  padding: 35px !important;
}

.padding-y-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.padding-x-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-35 {
    padding: 35px !important;
  }
  .padding-y-sm-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .padding-x-sm-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-35 {
    padding: 35px !important;
  }
  .padding-y-md-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .padding-x-md-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-35 {
    padding: 35px !important;
  }
  .padding-y-lg-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .padding-x-lg-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-35 {
    padding: 35px !important;
  }
  .padding-y-xl-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .padding-x-xl-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}
.p-40 {
  padding: 40px;
}

.p-x-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-y-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding-40 {
  padding: 40px !important;
}

.padding-y-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.padding-x-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-40 {
    padding: 40px !important;
  }
  .padding-y-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .padding-x-sm-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-40 {
    padding: 40px !important;
  }
  .padding-y-md-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .padding-x-md-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-40 {
    padding: 40px !important;
  }
  .padding-y-lg-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .padding-x-lg-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-40 {
    padding: 40px !important;
  }
  .padding-y-xl-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .padding-x-xl-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
.p-70 {
  padding: 70px;
}

.p-x-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-y-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.padding-70 {
  padding: 70px !important;
}

.padding-y-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.padding-x-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-70 {
    padding: 70px !important;
  }
  .padding-y-sm-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .padding-x-sm-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-70 {
    padding: 70px !important;
  }
  .padding-y-md-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .padding-x-md-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-70 {
    padding: 70px !important;
  }
  .padding-y-lg-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .padding-x-lg-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-70 {
    padding: 70px !important;
  }
  .padding-y-xl-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .padding-x-xl-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
}
.p-60 {
  padding: 60px;
}

.p-x-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-y-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.padding-60 {
  padding: 60px !important;
}

.padding-y-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.padding-x-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-60 {
    padding: 60px !important;
  }
  .padding-y-sm-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .padding-x-sm-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-60 {
    padding: 60px !important;
  }
  .padding-y-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .padding-x-md-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-60 {
    padding: 60px !important;
  }
  .padding-y-lg-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .padding-x-lg-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-60 {
    padding: 60px !important;
  }
  .padding-y-xl-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .padding-x-xl-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}
.p-50 {
  padding: 50px;
}

.p-x-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-y-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.padding-50 {
  padding: 50px !important;
}

.padding-y-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.padding-x-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-50 {
    padding: 50px !important;
  }
  .padding-y-sm-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .padding-x-sm-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-50 {
    padding: 50px !important;
  }
  .padding-y-md-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .padding-x-md-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-50 {
    padding: 50px !important;
  }
  .padding-y-lg-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .padding-x-lg-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-50 {
    padding: 50px !important;
  }
  .padding-y-xl-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .padding-x-xl-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}
.p-100 {
  padding: 100px;
}

.p-x-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-y-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.padding-100 {
  padding: 100px !important;
}

.padding-y-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.padding-x-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-100 {
    padding: 100px !important;
  }
  .padding-y-sm-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .padding-x-sm-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-100 {
    padding: 100px !important;
  }
  .padding-y-md-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .padding-x-md-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-100 {
    padding: 100px !important;
  }
  .padding-y-lg-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .padding-x-lg-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-100 {
    padding: 100px !important;
  }
  .padding-y-xl-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .padding-x-xl-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}
.p-140 {
  padding: 140px;
}

.p-x-140 {
  padding-left: 140px;
  padding-right: 140px;
}

.p-y-140 {
  padding-top: 140px;
  padding-bottom: 140px;
}

.padding-140 {
  padding: 140px !important;
}

.padding-y-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.padding-x-140 {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-140 {
    padding: 140px !important;
  }
  .padding-y-sm-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .padding-x-sm-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-140 {
    padding: 140px !important;
  }
  .padding-y-md-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .padding-x-md-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-140 {
    padding: 140px !important;
  }
  .padding-y-lg-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .padding-x-lg-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-140 {
    padding: 140px !important;
  }
  .padding-y-xl-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .padding-x-xl-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
}
.p-200 {
  padding: 200px;
}

.p-x-200 {
  padding-left: 200px;
  padding-right: 200px;
}

.p-y-200 {
  padding-top: 200px;
  padding-bottom: 200px;
}

.padding-200 {
  padding: 200px !important;
}

.padding-y-200 {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}

.padding-x-200 {
  padding-left: 200px !important;
  padding-right: 200px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-200 {
    padding: 200px !important;
  }
  .padding-y-sm-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .padding-x-sm-200 {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-200 {
    padding: 200px !important;
  }
  .padding-y-md-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .padding-x-md-200 {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-200 {
    padding: 200px !important;
  }
  .padding-y-lg-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .padding-x-lg-200 {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-200 {
    padding: 200px !important;
  }
  .padding-y-xl-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .padding-x-xl-200 {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
}
.p-270 {
  padding: 270px;
}

.p-x-270 {
  padding-left: 270px;
  padding-right: 270px;
}

.p-y-270 {
  padding-top: 270px;
  padding-bottom: 270px;
}

.padding-270 {
  padding: 270px !important;
}

.padding-y-270 {
  padding-top: 270px !important;
  padding-bottom: 270px !important;
}

.padding-x-270 {
  padding-left: 270px !important;
  padding-right: 270px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-270 {
    padding: 270px !important;
  }
  .padding-y-sm-270 {
    padding-top: 270px !important;
    padding-bottom: 270px !important;
  }
  .padding-x-sm-270 {
    padding-left: 270px !important;
    padding-right: 270px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-270 {
    padding: 270px !important;
  }
  .padding-y-md-270 {
    padding-top: 270px !important;
    padding-bottom: 270px !important;
  }
  .padding-x-md-270 {
    padding-left: 270px !important;
    padding-right: 270px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-270 {
    padding: 270px !important;
  }
  .padding-y-lg-270 {
    padding-top: 270px !important;
    padding-bottom: 270px !important;
  }
  .padding-x-lg-270 {
    padding-left: 270px !important;
    padding-right: 270px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-270 {
    padding: 270px !important;
  }
  .padding-y-xl-270 {
    padding-top: 270px !important;
    padding-bottom: 270px !important;
  }
  .padding-x-xl-270 {
    padding-left: 270px !important;
    padding-right: 270px !important;
  }
}
.p-10 {
  padding: 10px;
}

.p-x-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-y-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-10 {
  padding: 10px !important;
}

.padding-y-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.padding-x-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-10 {
    padding: 10px !important;
  }
  .padding-y-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .padding-x-sm-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-10 {
    padding: 10px !important;
  }
  .padding-y-md-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .padding-x-md-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-10 {
    padding: 10px !important;
  }
  .padding-y-lg-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .padding-x-lg-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-10 {
    padding: 10px !important;
  }
  .padding-y-xl-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .padding-x-xl-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
.p-m120 {
  padding: -120px;
}

.p-x-m120 {
  padding-left: -120px;
  padding-right: -120px;
}

.p-y-m120 {
  padding-top: -120px;
  padding-bottom: -120px;
}

.padding-m120 {
  padding: -120px !important;
}

.padding-y-m120 {
  padding-top: -120px !important;
  padding-bottom: -120px !important;
}

.padding-x-m120 {
  padding-left: -120px !important;
  padding-right: -120px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-m120 {
    padding: -120px !important;
  }
  .padding-y-sm-m120 {
    padding-top: -120px !important;
    padding-bottom: -120px !important;
  }
  .padding-x-sm-m120 {
    padding-left: -120px !important;
    padding-right: -120px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-m120 {
    padding: -120px !important;
  }
  .padding-y-md-m120 {
    padding-top: -120px !important;
    padding-bottom: -120px !important;
  }
  .padding-x-md-m120 {
    padding-left: -120px !important;
    padding-right: -120px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-m120 {
    padding: -120px !important;
  }
  .padding-y-lg-m120 {
    padding-top: -120px !important;
    padding-bottom: -120px !important;
  }
  .padding-x-lg-m120 {
    padding-left: -120px !important;
    padding-right: -120px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-m120 {
    padding: -120px !important;
  }
  .padding-y-xl-m120 {
    padding-top: -120px !important;
    padding-bottom: -120px !important;
  }
  .padding-x-xl-m120 {
    padding-left: -120px !important;
    padding-right: -120px !important;
  }
}
.p-m50 {
  padding: -50px;
}

.p-x-m50 {
  padding-left: -50px;
  padding-right: -50px;
}

.p-y-m50 {
  padding-top: -50px;
  padding-bottom: -50px;
}

.padding-m50 {
  padding: -50px !important;
}

.padding-y-m50 {
  padding-top: -50px !important;
  padding-bottom: -50px !important;
}

.padding-x-m50 {
  padding-left: -50px !important;
  padding-right: -50px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-m50 {
    padding: -50px !important;
  }
  .padding-y-sm-m50 {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
  .padding-x-sm-m50 {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-m50 {
    padding: -50px !important;
  }
  .padding-y-md-m50 {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
  .padding-x-md-m50 {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-m50 {
    padding: -50px !important;
  }
  .padding-y-lg-m50 {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
  .padding-x-lg-m50 {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-m50 {
    padding: -50px !important;
  }
  .padding-y-xl-m50 {
    padding-top: -50px !important;
    padding-bottom: -50px !important;
  }
  .padding-x-xl-m50 {
    padding-left: -50px !important;
    padding-right: -50px !important;
  }
}
.p-m10 {
  padding: -10px;
}

.p-x-m10 {
  padding-left: -10px;
  padding-right: -10px;
}

.p-y-m10 {
  padding-top: -10px;
  padding-bottom: -10px;
}

.padding-m10 {
  padding: -10px !important;
}

.padding-y-m10 {
  padding-top: -10px !important;
  padding-bottom: -10px !important;
}

.padding-x-m10 {
  padding-left: -10px !important;
  padding-right: -10px !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-m10 {
    padding: -10px !important;
  }
  .padding-y-sm-m10 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .padding-x-sm-m10 {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-m10 {
    padding: -10px !important;
  }
  .padding-y-md-m10 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .padding-x-md-m10 {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-m10 {
    padding: -10px !important;
  }
  .padding-y-lg-m10 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .padding-x-lg-m10 {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-m10 {
    padding: -10px !important;
  }
  .padding-y-xl-m10 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .padding-x-xl-m10 {
    padding-left: -10px !important;
    padding-right: -10px !important;
  }
}
.p-0 {
  padding: 0;
}

.p-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.p-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-0 {
  padding: 0 !important;
}

.padding-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.padding-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media screen and (min-width: 312px) {
  .padding-sm-0 {
    padding: 0 !important;
  }
  .padding-y-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .padding-x-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen and (min-width: 767px) {
  .padding-md-0 {
    padding: 0 !important;
  }
  .padding-y-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .padding-x-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen and (min-width: 992px) {
  .padding-lg-0 {
    padding: 0 !important;
  }
  .padding-y-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .padding-x-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen and (min-width: 1440px) {
  .padding-xl-0 {
    padding: 0 !important;
  }
  .padding-y-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .padding-x-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.m-auto {
  margin: auto;
}

.m-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.m-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.m-t-auto {
  margin-top: auto;
}

.top-auto {
  top: auto;
}

.left-auto {
  left: auto;
}

.right-auto {
  right: auto;
}

.m-auto {
  margin: auto !important;
}

.m-t-auto {
  margin-top: auto !important;
}

.m-b-auto {
  margin-bottom: auto !important;
}

.top-auto {
  top: auto !important;
}

@media screen and (min-width: 312px) {
  .m-sm-auto {
    margin: auto !important;
  }
  .m-t-sm-auto {
    margin-top: auto !important;
  }
  .m-b-sm-auto {
    margin-bottom: auto !important;
  }
  .top-sm-auto {
    top: auto !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-auto {
    margin: auto !important;
  }
  .m-t-md-auto {
    margin-top: auto !important;
  }
  .m-b-md-auto {
    margin-bottom: auto !important;
  }
  .top-md-auto {
    top: auto !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-auto {
    margin: auto !important;
  }
  .m-t-lg-auto {
    margin-top: auto !important;
  }
  .m-b-lg-auto {
    margin-bottom: auto !important;
  }
  .top-lg-auto {
    top: auto !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-auto {
    margin: auto !important;
  }
  .m-t-xl-auto {
    margin-top: auto !important;
  }
  .m-b-xl-auto {
    margin-bottom: auto !important;
  }
  .top-xl-auto {
    top: auto !important;
  }
}
.m-3 {
  margin: 3px;
}

.m-x-3 {
  margin-left: 3px;
  margin-right: 3px;
}

.m-y-3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.m-t-3 {
  margin-top: 3px;
}

.top-3 {
  top: 3px;
}

.left-3 {
  left: 3px;
}

.right-3 {
  right: 3px;
}

.m-3 {
  margin: 3px !important;
}

.m-t-3 {
  margin-top: 3px !important;
}

.m-b-3 {
  margin-bottom: 3px !important;
}

.top-3 {
  top: 3px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-3 {
    margin: 3px !important;
  }
  .m-t-sm-3 {
    margin-top: 3px !important;
  }
  .m-b-sm-3 {
    margin-bottom: 3px !important;
  }
  .top-sm-3 {
    top: 3px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-3 {
    margin: 3px !important;
  }
  .m-t-md-3 {
    margin-top: 3px !important;
  }
  .m-b-md-3 {
    margin-bottom: 3px !important;
  }
  .top-md-3 {
    top: 3px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-3 {
    margin: 3px !important;
  }
  .m-t-lg-3 {
    margin-top: 3px !important;
  }
  .m-b-lg-3 {
    margin-bottom: 3px !important;
  }
  .top-lg-3 {
    top: 3px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-3 {
    margin: 3px !important;
  }
  .m-t-xl-3 {
    margin-top: 3px !important;
  }
  .m-b-xl-3 {
    margin-bottom: 3px !important;
  }
  .top-xl-3 {
    top: 3px !important;
  }
}
.m-5 {
  margin: 5px;
}

.m-x-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-y-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.m-t-5 {
  margin-top: 5px;
}

.top-5 {
  top: 5px;
}

.left-5 {
  left: 5px;
}

.right-5 {
  right: 5px;
}

.m-5 {
  margin: 5px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.top-5 {
  top: 5px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-5 {
    margin: 5px !important;
  }
  .m-t-sm-5 {
    margin-top: 5px !important;
  }
  .m-b-sm-5 {
    margin-bottom: 5px !important;
  }
  .top-sm-5 {
    top: 5px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-5 {
    margin: 5px !important;
  }
  .m-t-md-5 {
    margin-top: 5px !important;
  }
  .m-b-md-5 {
    margin-bottom: 5px !important;
  }
  .top-md-5 {
    top: 5px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-5 {
    margin: 5px !important;
  }
  .m-t-lg-5 {
    margin-top: 5px !important;
  }
  .m-b-lg-5 {
    margin-bottom: 5px !important;
  }
  .top-lg-5 {
    top: 5px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-5 {
    margin: 5px !important;
  }
  .m-t-xl-5 {
    margin-top: 5px !important;
  }
  .m-b-xl-5 {
    margin-bottom: 5px !important;
  }
  .top-xl-5 {
    top: 5px !important;
  }
}
.m-20 {
  margin: 20px;
}

.m-x-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-y-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-t-20 {
  margin-top: 20px;
}

.top-20 {
  top: 20px;
}

.left-20 {
  left: 20px;
}

.right-20 {
  right: 20px;
}

.m-20 {
  margin: 20px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.top-20 {
  top: 20px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-20 {
    margin: 20px !important;
  }
  .m-t-sm-20 {
    margin-top: 20px !important;
  }
  .m-b-sm-20 {
    margin-bottom: 20px !important;
  }
  .top-sm-20 {
    top: 20px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-20 {
    margin: 20px !important;
  }
  .m-t-md-20 {
    margin-top: 20px !important;
  }
  .m-b-md-20 {
    margin-bottom: 20px !important;
  }
  .top-md-20 {
    top: 20px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-20 {
    margin: 20px !important;
  }
  .m-t-lg-20 {
    margin-top: 20px !important;
  }
  .m-b-lg-20 {
    margin-bottom: 20px !important;
  }
  .top-lg-20 {
    top: 20px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-20 {
    margin: 20px !important;
  }
  .m-t-xl-20 {
    margin-top: 20px !important;
  }
  .m-b-xl-20 {
    margin-bottom: 20px !important;
  }
  .top-xl-20 {
    top: 20px !important;
  }
}
.m-25 {
  margin: 25px;
}

.m-x-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.m-y-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.m-t-25 {
  margin-top: 25px;
}

.top-25 {
  top: 25px;
}

.left-25 {
  left: 25px;
}

.right-25 {
  right: 25px;
}

.m-25 {
  margin: 25px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.top-25 {
  top: 25px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-25 {
    margin: 25px !important;
  }
  .m-t-sm-25 {
    margin-top: 25px !important;
  }
  .m-b-sm-25 {
    margin-bottom: 25px !important;
  }
  .top-sm-25 {
    top: 25px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-25 {
    margin: 25px !important;
  }
  .m-t-md-25 {
    margin-top: 25px !important;
  }
  .m-b-md-25 {
    margin-bottom: 25px !important;
  }
  .top-md-25 {
    top: 25px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-25 {
    margin: 25px !important;
  }
  .m-t-lg-25 {
    margin-top: 25px !important;
  }
  .m-b-lg-25 {
    margin-bottom: 25px !important;
  }
  .top-lg-25 {
    top: 25px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-25 {
    margin: 25px !important;
  }
  .m-t-xl-25 {
    margin-top: 25px !important;
  }
  .m-b-xl-25 {
    margin-bottom: 25px !important;
  }
  .top-xl-25 {
    top: 25px !important;
  }
}
.m-30 {
  margin: 30px;
}

.m-x-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-y-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.m-t-30 {
  margin-top: 30px;
}

.top-30 {
  top: 30px;
}

.left-30 {
  left: 30px;
}

.right-30 {
  right: 30px;
}

.m-30 {
  margin: 30px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.top-30 {
  top: 30px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-30 {
    margin: 30px !important;
  }
  .m-t-sm-30 {
    margin-top: 30px !important;
  }
  .m-b-sm-30 {
    margin-bottom: 30px !important;
  }
  .top-sm-30 {
    top: 30px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-30 {
    margin: 30px !important;
  }
  .m-t-md-30 {
    margin-top: 30px !important;
  }
  .m-b-md-30 {
    margin-bottom: 30px !important;
  }
  .top-md-30 {
    top: 30px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-30 {
    margin: 30px !important;
  }
  .m-t-lg-30 {
    margin-top: 30px !important;
  }
  .m-b-lg-30 {
    margin-bottom: 30px !important;
  }
  .top-lg-30 {
    top: 30px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-30 {
    margin: 30px !important;
  }
  .m-t-xl-30 {
    margin-top: 30px !important;
  }
  .m-b-xl-30 {
    margin-bottom: 30px !important;
  }
  .top-xl-30 {
    top: 30px !important;
  }
}
.m-35 {
  margin: 35px;
}

.m-x-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.m-y-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.m-t-35 {
  margin-top: 35px;
}

.top-35 {
  top: 35px;
}

.left-35 {
  left: 35px;
}

.right-35 {
  right: 35px;
}

.m-35 {
  margin: 35px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.top-35 {
  top: 35px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-35 {
    margin: 35px !important;
  }
  .m-t-sm-35 {
    margin-top: 35px !important;
  }
  .m-b-sm-35 {
    margin-bottom: 35px !important;
  }
  .top-sm-35 {
    top: 35px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-35 {
    margin: 35px !important;
  }
  .m-t-md-35 {
    margin-top: 35px !important;
  }
  .m-b-md-35 {
    margin-bottom: 35px !important;
  }
  .top-md-35 {
    top: 35px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-35 {
    margin: 35px !important;
  }
  .m-t-lg-35 {
    margin-top: 35px !important;
  }
  .m-b-lg-35 {
    margin-bottom: 35px !important;
  }
  .top-lg-35 {
    top: 35px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-35 {
    margin: 35px !important;
  }
  .m-t-xl-35 {
    margin-top: 35px !important;
  }
  .m-b-xl-35 {
    margin-bottom: 35px !important;
  }
  .top-xl-35 {
    top: 35px !important;
  }
}
.m-40 {
  margin: 40px;
}

.m-x-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-y-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.m-t-40 {
  margin-top: 40px;
}

.top-40 {
  top: 40px;
}

.left-40 {
  left: 40px;
}

.right-40 {
  right: 40px;
}

.m-40 {
  margin: 40px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.top-40 {
  top: 40px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-40 {
    margin: 40px !important;
  }
  .m-t-sm-40 {
    margin-top: 40px !important;
  }
  .m-b-sm-40 {
    margin-bottom: 40px !important;
  }
  .top-sm-40 {
    top: 40px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-40 {
    margin: 40px !important;
  }
  .m-t-md-40 {
    margin-top: 40px !important;
  }
  .m-b-md-40 {
    margin-bottom: 40px !important;
  }
  .top-md-40 {
    top: 40px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-40 {
    margin: 40px !important;
  }
  .m-t-lg-40 {
    margin-top: 40px !important;
  }
  .m-b-lg-40 {
    margin-bottom: 40px !important;
  }
  .top-lg-40 {
    top: 40px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-40 {
    margin: 40px !important;
  }
  .m-t-xl-40 {
    margin-top: 40px !important;
  }
  .m-b-xl-40 {
    margin-bottom: 40px !important;
  }
  .top-xl-40 {
    top: 40px !important;
  }
}
.m-70 {
  margin: 70px;
}

.m-x-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-y-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.m-t-70 {
  margin-top: 70px;
}

.top-70 {
  top: 70px;
}

.left-70 {
  left: 70px;
}

.right-70 {
  right: 70px;
}

.m-70 {
  margin: 70px !important;
}

.m-t-70 {
  margin-top: 70px !important;
}

.m-b-70 {
  margin-bottom: 70px !important;
}

.top-70 {
  top: 70px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-70 {
    margin: 70px !important;
  }
  .m-t-sm-70 {
    margin-top: 70px !important;
  }
  .m-b-sm-70 {
    margin-bottom: 70px !important;
  }
  .top-sm-70 {
    top: 70px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-70 {
    margin: 70px !important;
  }
  .m-t-md-70 {
    margin-top: 70px !important;
  }
  .m-b-md-70 {
    margin-bottom: 70px !important;
  }
  .top-md-70 {
    top: 70px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-70 {
    margin: 70px !important;
  }
  .m-t-lg-70 {
    margin-top: 70px !important;
  }
  .m-b-lg-70 {
    margin-bottom: 70px !important;
  }
  .top-lg-70 {
    top: 70px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-70 {
    margin: 70px !important;
  }
  .m-t-xl-70 {
    margin-top: 70px !important;
  }
  .m-b-xl-70 {
    margin-bottom: 70px !important;
  }
  .top-xl-70 {
    top: 70px !important;
  }
}
.m-60 {
  margin: 60px;
}

.m-x-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-y-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.m-t-60 {
  margin-top: 60px;
}

.top-60 {
  top: 60px;
}

.left-60 {
  left: 60px;
}

.right-60 {
  right: 60px;
}

.m-60 {
  margin: 60px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.top-60 {
  top: 60px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-60 {
    margin: 60px !important;
  }
  .m-t-sm-60 {
    margin-top: 60px !important;
  }
  .m-b-sm-60 {
    margin-bottom: 60px !important;
  }
  .top-sm-60 {
    top: 60px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-60 {
    margin: 60px !important;
  }
  .m-t-md-60 {
    margin-top: 60px !important;
  }
  .m-b-md-60 {
    margin-bottom: 60px !important;
  }
  .top-md-60 {
    top: 60px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-60 {
    margin: 60px !important;
  }
  .m-t-lg-60 {
    margin-top: 60px !important;
  }
  .m-b-lg-60 {
    margin-bottom: 60px !important;
  }
  .top-lg-60 {
    top: 60px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-60 {
    margin: 60px !important;
  }
  .m-t-xl-60 {
    margin-top: 60px !important;
  }
  .m-b-xl-60 {
    margin-bottom: 60px !important;
  }
  .top-xl-60 {
    top: 60px !important;
  }
}
.m-50 {
  margin: 50px;
}

.m-x-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-y-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.m-t-50 {
  margin-top: 50px;
}

.top-50 {
  top: 50px;
}

.left-50 {
  left: 50px;
}

.right-50 {
  right: 50px;
}

.m-50 {
  margin: 50px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.top-50 {
  top: 50px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-50 {
    margin: 50px !important;
  }
  .m-t-sm-50 {
    margin-top: 50px !important;
  }
  .m-b-sm-50 {
    margin-bottom: 50px !important;
  }
  .top-sm-50 {
    top: 50px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-50 {
    margin: 50px !important;
  }
  .m-t-md-50 {
    margin-top: 50px !important;
  }
  .m-b-md-50 {
    margin-bottom: 50px !important;
  }
  .top-md-50 {
    top: 50px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-50 {
    margin: 50px !important;
  }
  .m-t-lg-50 {
    margin-top: 50px !important;
  }
  .m-b-lg-50 {
    margin-bottom: 50px !important;
  }
  .top-lg-50 {
    top: 50px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-50 {
    margin: 50px !important;
  }
  .m-t-xl-50 {
    margin-top: 50px !important;
  }
  .m-b-xl-50 {
    margin-bottom: 50px !important;
  }
  .top-xl-50 {
    top: 50px !important;
  }
}
.m-100 {
  margin: 100px;
}

.m-x-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-y-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.m-t-100 {
  margin-top: 100px;
}

.top-100 {
  top: 100px;
}

.left-100 {
  left: 100px;
}

.right-100 {
  right: 100px;
}

.m-100 {
  margin: 100px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.top-100 {
  top: 100px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-100 {
    margin: 100px !important;
  }
  .m-t-sm-100 {
    margin-top: 100px !important;
  }
  .m-b-sm-100 {
    margin-bottom: 100px !important;
  }
  .top-sm-100 {
    top: 100px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-100 {
    margin: 100px !important;
  }
  .m-t-md-100 {
    margin-top: 100px !important;
  }
  .m-b-md-100 {
    margin-bottom: 100px !important;
  }
  .top-md-100 {
    top: 100px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-100 {
    margin: 100px !important;
  }
  .m-t-lg-100 {
    margin-top: 100px !important;
  }
  .m-b-lg-100 {
    margin-bottom: 100px !important;
  }
  .top-lg-100 {
    top: 100px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-100 {
    margin: 100px !important;
  }
  .m-t-xl-100 {
    margin-top: 100px !important;
  }
  .m-b-xl-100 {
    margin-bottom: 100px !important;
  }
  .top-xl-100 {
    top: 100px !important;
  }
}
.m-140 {
  margin: 140px;
}

.m-x-140 {
  margin-left: 140px;
  margin-right: 140px;
}

.m-y-140 {
  margin-top: 140px;
  margin-bottom: 140px;
}

.m-t-140 {
  margin-top: 140px;
}

.top-140 {
  top: 140px;
}

.left-140 {
  left: 140px;
}

.right-140 {
  right: 140px;
}

.m-140 {
  margin: 140px !important;
}

.m-t-140 {
  margin-top: 140px !important;
}

.m-b-140 {
  margin-bottom: 140px !important;
}

.top-140 {
  top: 140px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-140 {
    margin: 140px !important;
  }
  .m-t-sm-140 {
    margin-top: 140px !important;
  }
  .m-b-sm-140 {
    margin-bottom: 140px !important;
  }
  .top-sm-140 {
    top: 140px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-140 {
    margin: 140px !important;
  }
  .m-t-md-140 {
    margin-top: 140px !important;
  }
  .m-b-md-140 {
    margin-bottom: 140px !important;
  }
  .top-md-140 {
    top: 140px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-140 {
    margin: 140px !important;
  }
  .m-t-lg-140 {
    margin-top: 140px !important;
  }
  .m-b-lg-140 {
    margin-bottom: 140px !important;
  }
  .top-lg-140 {
    top: 140px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-140 {
    margin: 140px !important;
  }
  .m-t-xl-140 {
    margin-top: 140px !important;
  }
  .m-b-xl-140 {
    margin-bottom: 140px !important;
  }
  .top-xl-140 {
    top: 140px !important;
  }
}
.m-200 {
  margin: 200px;
}

.m-x-200 {
  margin-left: 200px;
  margin-right: 200px;
}

.m-y-200 {
  margin-top: 200px;
  margin-bottom: 200px;
}

.m-t-200 {
  margin-top: 200px;
}

.top-200 {
  top: 200px;
}

.left-200 {
  left: 200px;
}

.right-200 {
  right: 200px;
}

.m-200 {
  margin: 200px !important;
}

.m-t-200 {
  margin-top: 200px !important;
}

.m-b-200 {
  margin-bottom: 200px !important;
}

.top-200 {
  top: 200px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-200 {
    margin: 200px !important;
  }
  .m-t-sm-200 {
    margin-top: 200px !important;
  }
  .m-b-sm-200 {
    margin-bottom: 200px !important;
  }
  .top-sm-200 {
    top: 200px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-200 {
    margin: 200px !important;
  }
  .m-t-md-200 {
    margin-top: 200px !important;
  }
  .m-b-md-200 {
    margin-bottom: 200px !important;
  }
  .top-md-200 {
    top: 200px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-200 {
    margin: 200px !important;
  }
  .m-t-lg-200 {
    margin-top: 200px !important;
  }
  .m-b-lg-200 {
    margin-bottom: 200px !important;
  }
  .top-lg-200 {
    top: 200px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-200 {
    margin: 200px !important;
  }
  .m-t-xl-200 {
    margin-top: 200px !important;
  }
  .m-b-xl-200 {
    margin-bottom: 200px !important;
  }
  .top-xl-200 {
    top: 200px !important;
  }
}
.m-270 {
  margin: 270px;
}

.m-x-270 {
  margin-left: 270px;
  margin-right: 270px;
}

.m-y-270 {
  margin-top: 270px;
  margin-bottom: 270px;
}

.m-t-270 {
  margin-top: 270px;
}

.top-270 {
  top: 270px;
}

.left-270 {
  left: 270px;
}

.right-270 {
  right: 270px;
}

.m-270 {
  margin: 270px !important;
}

.m-t-270 {
  margin-top: 270px !important;
}

.m-b-270 {
  margin-bottom: 270px !important;
}

.top-270 {
  top: 270px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-270 {
    margin: 270px !important;
  }
  .m-t-sm-270 {
    margin-top: 270px !important;
  }
  .m-b-sm-270 {
    margin-bottom: 270px !important;
  }
  .top-sm-270 {
    top: 270px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-270 {
    margin: 270px !important;
  }
  .m-t-md-270 {
    margin-top: 270px !important;
  }
  .m-b-md-270 {
    margin-bottom: 270px !important;
  }
  .top-md-270 {
    top: 270px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-270 {
    margin: 270px !important;
  }
  .m-t-lg-270 {
    margin-top: 270px !important;
  }
  .m-b-lg-270 {
    margin-bottom: 270px !important;
  }
  .top-lg-270 {
    top: 270px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-270 {
    margin: 270px !important;
  }
  .m-t-xl-270 {
    margin-top: 270px !important;
  }
  .m-b-xl-270 {
    margin-bottom: 270px !important;
  }
  .top-xl-270 {
    top: 270px !important;
  }
}
.m-10 {
  margin: 10px;
}

.m-x-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-y-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-t-10 {
  margin-top: 10px;
}

.top-10 {
  top: 10px;
}

.left-10 {
  left: 10px;
}

.right-10 {
  right: 10px;
}

.m-10 {
  margin: 10px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.top-10 {
  top: 10px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-10 {
    margin: 10px !important;
  }
  .m-t-sm-10 {
    margin-top: 10px !important;
  }
  .m-b-sm-10 {
    margin-bottom: 10px !important;
  }
  .top-sm-10 {
    top: 10px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-10 {
    margin: 10px !important;
  }
  .m-t-md-10 {
    margin-top: 10px !important;
  }
  .m-b-md-10 {
    margin-bottom: 10px !important;
  }
  .top-md-10 {
    top: 10px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-10 {
    margin: 10px !important;
  }
  .m-t-lg-10 {
    margin-top: 10px !important;
  }
  .m-b-lg-10 {
    margin-bottom: 10px !important;
  }
  .top-lg-10 {
    top: 10px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-10 {
    margin: 10px !important;
  }
  .m-t-xl-10 {
    margin-top: 10px !important;
  }
  .m-b-xl-10 {
    margin-bottom: 10px !important;
  }
  .top-xl-10 {
    top: 10px !important;
  }
}
.m-m120 {
  margin: -120px;
}

.m-x-m120 {
  margin-left: -120px;
  margin-right: -120px;
}

.m-y-m120 {
  margin-top: -120px;
  margin-bottom: -120px;
}

.m-t-m120 {
  margin-top: -120px;
}

.top-m120 {
  top: -120px;
}

.left-m120 {
  left: -120px;
}

.right-m120 {
  right: -120px;
}

.m-m120 {
  margin: -120px !important;
}

.m-t-m120 {
  margin-top: -120px !important;
}

.m-b-m120 {
  margin-bottom: -120px !important;
}

.top-m120 {
  top: -120px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-m120 {
    margin: -120px !important;
  }
  .m-t-sm-m120 {
    margin-top: -120px !important;
  }
  .m-b-sm-m120 {
    margin-bottom: -120px !important;
  }
  .top-sm-m120 {
    top: -120px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-m120 {
    margin: -120px !important;
  }
  .m-t-md-m120 {
    margin-top: -120px !important;
  }
  .m-b-md-m120 {
    margin-bottom: -120px !important;
  }
  .top-md-m120 {
    top: -120px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-m120 {
    margin: -120px !important;
  }
  .m-t-lg-m120 {
    margin-top: -120px !important;
  }
  .m-b-lg-m120 {
    margin-bottom: -120px !important;
  }
  .top-lg-m120 {
    top: -120px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-m120 {
    margin: -120px !important;
  }
  .m-t-xl-m120 {
    margin-top: -120px !important;
  }
  .m-b-xl-m120 {
    margin-bottom: -120px !important;
  }
  .top-xl-m120 {
    top: -120px !important;
  }
}
.m-m50 {
  margin: -50px;
}

.m-x-m50 {
  margin-left: -50px;
  margin-right: -50px;
}

.m-y-m50 {
  margin-top: -50px;
  margin-bottom: -50px;
}

.m-t-m50 {
  margin-top: -50px;
}

.top-m50 {
  top: -50px;
}

.left-m50 {
  left: -50px;
}

.right-m50 {
  right: -50px;
}

.m-m50 {
  margin: -50px !important;
}

.m-t-m50 {
  margin-top: -50px !important;
}

.m-b-m50 {
  margin-bottom: -50px !important;
}

.top-m50 {
  top: -50px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-m50 {
    margin: -50px !important;
  }
  .m-t-sm-m50 {
    margin-top: -50px !important;
  }
  .m-b-sm-m50 {
    margin-bottom: -50px !important;
  }
  .top-sm-m50 {
    top: -50px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-m50 {
    margin: -50px !important;
  }
  .m-t-md-m50 {
    margin-top: -50px !important;
  }
  .m-b-md-m50 {
    margin-bottom: -50px !important;
  }
  .top-md-m50 {
    top: -50px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-m50 {
    margin: -50px !important;
  }
  .m-t-lg-m50 {
    margin-top: -50px !important;
  }
  .m-b-lg-m50 {
    margin-bottom: -50px !important;
  }
  .top-lg-m50 {
    top: -50px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-m50 {
    margin: -50px !important;
  }
  .m-t-xl-m50 {
    margin-top: -50px !important;
  }
  .m-b-xl-m50 {
    margin-bottom: -50px !important;
  }
  .top-xl-m50 {
    top: -50px !important;
  }
}
.m-m10 {
  margin: -10px;
}

.m-x-m10 {
  margin-left: -10px;
  margin-right: -10px;
}

.m-y-m10 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.m-t-m10 {
  margin-top: -10px;
}

.top-m10 {
  top: -10px;
}

.left-m10 {
  left: -10px;
}

.right-m10 {
  right: -10px;
}

.m-m10 {
  margin: -10px !important;
}

.m-t-m10 {
  margin-top: -10px !important;
}

.m-b-m10 {
  margin-bottom: -10px !important;
}

.top-m10 {
  top: -10px !important;
}

@media screen and (min-width: 312px) {
  .m-sm-m10 {
    margin: -10px !important;
  }
  .m-t-sm-m10 {
    margin-top: -10px !important;
  }
  .m-b-sm-m10 {
    margin-bottom: -10px !important;
  }
  .top-sm-m10 {
    top: -10px !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-m10 {
    margin: -10px !important;
  }
  .m-t-md-m10 {
    margin-top: -10px !important;
  }
  .m-b-md-m10 {
    margin-bottom: -10px !important;
  }
  .top-md-m10 {
    top: -10px !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-m10 {
    margin: -10px !important;
  }
  .m-t-lg-m10 {
    margin-top: -10px !important;
  }
  .m-b-lg-m10 {
    margin-bottom: -10px !important;
  }
  .top-lg-m10 {
    top: -10px !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-m10 {
    margin: -10px !important;
  }
  .m-t-xl-m10 {
    margin-top: -10px !important;
  }
  .m-b-xl-m10 {
    margin-bottom: -10px !important;
  }
  .top-xl-m10 {
    top: -10px !important;
  }
}
.m-0 {
  margin: 0;
}

.m-x-0 {
  margin-left: 0;
  margin-right: 0;
}

.m-y-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-t-0 {
  margin-top: 0;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.m-0 {
  margin: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.top-0 {
  top: 0 !important;
}

@media screen and (min-width: 312px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-t-sm-0 {
    margin-top: 0 !important;
  }
  .m-b-sm-0 {
    margin-bottom: 0 !important;
  }
  .top-sm-0 {
    top: 0 !important;
  }
}
@media screen and (min-width: 767px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .m-t-md-0 {
    margin-top: 0 !important;
  }
  .m-b-md-0 {
    margin-bottom: 0 !important;
  }
  .top-md-0 {
    top: 0 !important;
  }
}
@media screen and (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-t-lg-0 {
    margin-top: 0 !important;
  }
  .m-b-lg-0 {
    margin-bottom: 0 !important;
  }
  .top-lg-0 {
    top: 0 !important;
  }
}
@media screen and (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-t-xl-0 {
    margin-top: 0 !important;
  }
  .m-b-xl-0 {
    margin-bottom: 0 !important;
  }
  .top-xl-0 {
    top: 0 !important;
  }
}
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.d-none {
  display: none;
}

.w-37 {
  width: 37px;
}

.h-37 {
  height: 37px;
}

.maxw-37 {
  max-width: 37px;
}

.width-xs-37 {
  width: 37px;
}

.height-xs-37 {
  height: 37px;
}

.maxw-xs-37 {
  max-width: 37px;
}

@media screen and (min-width: 312px) {
  .width-sm-37 {
    width: 37px;
  }
  .height-sm-37 {
    height: 37px;
  }
  .maxw-sm-37 {
    max-width: 37px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-37 {
    width: 37px;
  }
  .height-md-37 {
    height: 37px;
  }
  .maxw-md-37 {
    max-width: 37px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-37 {
    width: 37px;
  }
  .height-lg-37 {
    height: 37px;
  }
  .maxw-lg-37 {
    max-width: 37px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-37 {
    width: 37px;
  }
  .height-xl-37 {
    height: 37px;
  }
  .maxw-xl-37 {
    max-width: 37px;
  }
}
.w-100 {
  width: 100px;
}

.h-100 {
  height: 100px;
}

.maxw-100 {
  max-width: 100px;
}

.width-xs-100 {
  width: 100px;
}

.height-xs-100 {
  height: 100px;
}

.maxw-xs-100 {
  max-width: 100px;
}

@media screen and (min-width: 312px) {
  .width-sm-100 {
    width: 100px;
  }
  .height-sm-100 {
    height: 100px;
  }
  .maxw-sm-100 {
    max-width: 100px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-100 {
    width: 100px;
  }
  .height-md-100 {
    height: 100px;
  }
  .maxw-md-100 {
    max-width: 100px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-100 {
    width: 100px;
  }
  .height-lg-100 {
    height: 100px;
  }
  .maxw-lg-100 {
    max-width: 100px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-100 {
    width: 100px;
  }
  .height-xl-100 {
    height: 100px;
  }
  .maxw-xl-100 {
    max-width: 100px;
  }
}
.w-185 {
  width: 185px;
}

.h-185 {
  height: 185px;
}

.maxw-185 {
  max-width: 185px;
}

.width-xs-185 {
  width: 185px;
}

.height-xs-185 {
  height: 185px;
}

.maxw-xs-185 {
  max-width: 185px;
}

@media screen and (min-width: 312px) {
  .width-sm-185 {
    width: 185px;
  }
  .height-sm-185 {
    height: 185px;
  }
  .maxw-sm-185 {
    max-width: 185px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-185 {
    width: 185px;
  }
  .height-md-185 {
    height: 185px;
  }
  .maxw-md-185 {
    max-width: 185px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-185 {
    width: 185px;
  }
  .height-lg-185 {
    height: 185px;
  }
  .maxw-lg-185 {
    max-width: 185px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-185 {
    width: 185px;
  }
  .height-xl-185 {
    height: 185px;
  }
  .maxw-xl-185 {
    max-width: 185px;
  }
}
.w-243 {
  width: 243px;
}

.h-243 {
  height: 243px;
}

.maxw-243 {
  max-width: 243px;
}

.width-xs-243 {
  width: 243px;
}

.height-xs-243 {
  height: 243px;
}

.maxw-xs-243 {
  max-width: 243px;
}

@media screen and (min-width: 312px) {
  .width-sm-243 {
    width: 243px;
  }
  .height-sm-243 {
    height: 243px;
  }
  .maxw-sm-243 {
    max-width: 243px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-243 {
    width: 243px;
  }
  .height-md-243 {
    height: 243px;
  }
  .maxw-md-243 {
    max-width: 243px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-243 {
    width: 243px;
  }
  .height-lg-243 {
    height: 243px;
  }
  .maxw-lg-243 {
    max-width: 243px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-243 {
    width: 243px;
  }
  .height-xl-243 {
    height: 243px;
  }
  .maxw-xl-243 {
    max-width: 243px;
  }
}
.w-248 {
  width: 248px;
}

.h-248 {
  height: 248px;
}

.maxw-248 {
  max-width: 248px;
}

.width-xs-248 {
  width: 248px;
}

.height-xs-248 {
  height: 248px;
}

.maxw-xs-248 {
  max-width: 248px;
}

@media screen and (min-width: 312px) {
  .width-sm-248 {
    width: 248px;
  }
  .height-sm-248 {
    height: 248px;
  }
  .maxw-sm-248 {
    max-width: 248px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-248 {
    width: 248px;
  }
  .height-md-248 {
    height: 248px;
  }
  .maxw-md-248 {
    max-width: 248px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-248 {
    width: 248px;
  }
  .height-lg-248 {
    height: 248px;
  }
  .maxw-lg-248 {
    max-width: 248px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-248 {
    width: 248px;
  }
  .height-xl-248 {
    height: 248px;
  }
  .maxw-xl-248 {
    max-width: 248px;
  }
}
.w-263 {
  width: 263px;
}

.h-263 {
  height: 263px;
}

.maxw-263 {
  max-width: 263px;
}

.width-xs-263 {
  width: 263px;
}

.height-xs-263 {
  height: 263px;
}

.maxw-xs-263 {
  max-width: 263px;
}

@media screen and (min-width: 312px) {
  .width-sm-263 {
    width: 263px;
  }
  .height-sm-263 {
    height: 263px;
  }
  .maxw-sm-263 {
    max-width: 263px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-263 {
    width: 263px;
  }
  .height-md-263 {
    height: 263px;
  }
  .maxw-md-263 {
    max-width: 263px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-263 {
    width: 263px;
  }
  .height-lg-263 {
    height: 263px;
  }
  .maxw-lg-263 {
    max-width: 263px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-263 {
    width: 263px;
  }
  .height-xl-263 {
    height: 263px;
  }
  .maxw-xl-263 {
    max-width: 263px;
  }
}
.w-257 {
  width: 257px;
}

.h-257 {
  height: 257px;
}

.maxw-257 {
  max-width: 257px;
}

.width-xs-257 {
  width: 257px;
}

.height-xs-257 {
  height: 257px;
}

.maxw-xs-257 {
  max-width: 257px;
}

@media screen and (min-width: 312px) {
  .width-sm-257 {
    width: 257px;
  }
  .height-sm-257 {
    height: 257px;
  }
  .maxw-sm-257 {
    max-width: 257px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-257 {
    width: 257px;
  }
  .height-md-257 {
    height: 257px;
  }
  .maxw-md-257 {
    max-width: 257px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-257 {
    width: 257px;
  }
  .height-lg-257 {
    height: 257px;
  }
  .maxw-lg-257 {
    max-width: 257px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-257 {
    width: 257px;
  }
  .height-xl-257 {
    height: 257px;
  }
  .maxw-xl-257 {
    max-width: 257px;
  }
}
.w-271 {
  width: 271px;
}

.h-271 {
  height: 271px;
}

.maxw-271 {
  max-width: 271px;
}

.width-xs-271 {
  width: 271px;
}

.height-xs-271 {
  height: 271px;
}

.maxw-xs-271 {
  max-width: 271px;
}

@media screen and (min-width: 312px) {
  .width-sm-271 {
    width: 271px;
  }
  .height-sm-271 {
    height: 271px;
  }
  .maxw-sm-271 {
    max-width: 271px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-271 {
    width: 271px;
  }
  .height-md-271 {
    height: 271px;
  }
  .maxw-md-271 {
    max-width: 271px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-271 {
    width: 271px;
  }
  .height-lg-271 {
    height: 271px;
  }
  .maxw-lg-271 {
    max-width: 271px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-271 {
    width: 271px;
  }
  .height-xl-271 {
    height: 271px;
  }
  .maxw-xl-271 {
    max-width: 271px;
  }
}
.w-350 {
  width: 350px;
}

.h-350 {
  height: 350px;
}

.maxw-350 {
  max-width: 350px;
}

.width-xs-350 {
  width: 350px;
}

.height-xs-350 {
  height: 350px;
}

.maxw-xs-350 {
  max-width: 350px;
}

@media screen and (min-width: 312px) {
  .width-sm-350 {
    width: 350px;
  }
  .height-sm-350 {
    height: 350px;
  }
  .maxw-sm-350 {
    max-width: 350px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-350 {
    width: 350px;
  }
  .height-md-350 {
    height: 350px;
  }
  .maxw-md-350 {
    max-width: 350px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-350 {
    width: 350px;
  }
  .height-lg-350 {
    height: 350px;
  }
  .maxw-lg-350 {
    max-width: 350px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-350 {
    width: 350px;
  }
  .height-xl-350 {
    height: 350px;
  }
  .maxw-xl-350 {
    max-width: 350px;
  }
}
.w-400 {
  width: 400px;
}

.h-400 {
  height: 400px;
}

.maxw-400 {
  max-width: 400px;
}

.width-xs-400 {
  width: 400px;
}

.height-xs-400 {
  height: 400px;
}

.maxw-xs-400 {
  max-width: 400px;
}

@media screen and (min-width: 312px) {
  .width-sm-400 {
    width: 400px;
  }
  .height-sm-400 {
    height: 400px;
  }
  .maxw-sm-400 {
    max-width: 400px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-400 {
    width: 400px;
  }
  .height-md-400 {
    height: 400px;
  }
  .maxw-md-400 {
    max-width: 400px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-400 {
    width: 400px;
  }
  .height-lg-400 {
    height: 400px;
  }
  .maxw-lg-400 {
    max-width: 400px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-400 {
    width: 400px;
  }
  .height-xl-400 {
    height: 400px;
  }
  .maxw-xl-400 {
    max-width: 400px;
  }
}
.w-478 {
  width: 478px;
}

.h-478 {
  height: 478px;
}

.maxw-478 {
  max-width: 478px;
}

.width-xs-478 {
  width: 478px;
}

.height-xs-478 {
  height: 478px;
}

.maxw-xs-478 {
  max-width: 478px;
}

@media screen and (min-width: 312px) {
  .width-sm-478 {
    width: 478px;
  }
  .height-sm-478 {
    height: 478px;
  }
  .maxw-sm-478 {
    max-width: 478px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-478 {
    width: 478px;
  }
  .height-md-478 {
    height: 478px;
  }
  .maxw-md-478 {
    max-width: 478px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-478 {
    width: 478px;
  }
  .height-lg-478 {
    height: 478px;
  }
  .maxw-lg-478 {
    max-width: 478px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-478 {
    width: 478px;
  }
  .height-xl-478 {
    height: 478px;
  }
  .maxw-xl-478 {
    max-width: 478px;
  }
}
.w-629 {
  width: 629px;
}

.h-629 {
  height: 629px;
}

.maxw-629 {
  max-width: 629px;
}

.width-xs-629 {
  width: 629px;
}

.height-xs-629 {
  height: 629px;
}

.maxw-xs-629 {
  max-width: 629px;
}

@media screen and (min-width: 312px) {
  .width-sm-629 {
    width: 629px;
  }
  .height-sm-629 {
    height: 629px;
  }
  .maxw-sm-629 {
    max-width: 629px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-629 {
    width: 629px;
  }
  .height-md-629 {
    height: 629px;
  }
  .maxw-md-629 {
    max-width: 629px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-629 {
    width: 629px;
  }
  .height-lg-629 {
    height: 629px;
  }
  .maxw-lg-629 {
    max-width: 629px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-629 {
    width: 629px;
  }
  .height-xl-629 {
    height: 629px;
  }
  .maxw-xl-629 {
    max-width: 629px;
  }
}
.w-664 {
  width: 664px;
}

.h-664 {
  height: 664px;
}

.maxw-664 {
  max-width: 664px;
}

.width-xs-664 {
  width: 664px;
}

.height-xs-664 {
  height: 664px;
}

.maxw-xs-664 {
  max-width: 664px;
}

@media screen and (min-width: 312px) {
  .width-sm-664 {
    width: 664px;
  }
  .height-sm-664 {
    height: 664px;
  }
  .maxw-sm-664 {
    max-width: 664px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-664 {
    width: 664px;
  }
  .height-md-664 {
    height: 664px;
  }
  .maxw-md-664 {
    max-width: 664px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-664 {
    width: 664px;
  }
  .height-lg-664 {
    height: 664px;
  }
  .maxw-lg-664 {
    max-width: 664px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-664 {
    width: 664px;
  }
  .height-xl-664 {
    height: 664px;
  }
  .maxw-xl-664 {
    max-width: 664px;
  }
}
.w-676 {
  width: 676px;
}

.h-676 {
  height: 676px;
}

.maxw-676 {
  max-width: 676px;
}

.width-xs-676 {
  width: 676px;
}

.height-xs-676 {
  height: 676px;
}

.maxw-xs-676 {
  max-width: 676px;
}

@media screen and (min-width: 312px) {
  .width-sm-676 {
    width: 676px;
  }
  .height-sm-676 {
    height: 676px;
  }
  .maxw-sm-676 {
    max-width: 676px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-676 {
    width: 676px;
  }
  .height-md-676 {
    height: 676px;
  }
  .maxw-md-676 {
    max-width: 676px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-676 {
    width: 676px;
  }
  .height-lg-676 {
    height: 676px;
  }
  .maxw-lg-676 {
    max-width: 676px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-676 {
    width: 676px;
  }
  .height-xl-676 {
    height: 676px;
  }
  .maxw-xl-676 {
    max-width: 676px;
  }
}
.w-50per {
  width: 50%;
}

.h-50per {
  height: 50%;
}

.maxw-50per {
  max-width: 50%;
}

.width-xs-50per {
  width: 50%;
}

.height-xs-50per {
  height: 50%;
}

.maxw-xs-50per {
  max-width: 50%;
}

@media screen and (min-width: 312px) {
  .width-sm-50per {
    width: 50%;
  }
  .height-sm-50per {
    height: 50%;
  }
  .maxw-sm-50per {
    max-width: 50%;
  }
}
@media screen and (min-width: 767px) {
  .width-md-50per {
    width: 50%;
  }
  .height-md-50per {
    height: 50%;
  }
  .maxw-md-50per {
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-50per {
    width: 50%;
  }
  .height-lg-50per {
    height: 50%;
  }
  .maxw-lg-50per {
    max-width: 50%;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-50per {
    width: 50%;
  }
  .height-xl-50per {
    height: 50%;
  }
  .maxw-xl-50per {
    max-width: 50%;
  }
}
.w-181 {
  width: 181px;
}

.h-181 {
  height: 181px;
}

.maxw-181 {
  max-width: 181px;
}

.width-xs-181 {
  width: 181px;
}

.height-xs-181 {
  height: 181px;
}

.maxw-xs-181 {
  max-width: 181px;
}

@media screen and (min-width: 312px) {
  .width-sm-181 {
    width: 181px;
  }
  .height-sm-181 {
    height: 181px;
  }
  .maxw-sm-181 {
    max-width: 181px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-181 {
    width: 181px;
  }
  .height-md-181 {
    height: 181px;
  }
  .maxw-md-181 {
    max-width: 181px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-181 {
    width: 181px;
  }
  .height-lg-181 {
    height: 181px;
  }
  .maxw-lg-181 {
    max-width: 181px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-181 {
    width: 181px;
  }
  .height-xl-181 {
    height: 181px;
  }
  .maxw-xl-181 {
    max-width: 181px;
  }
}
.w-188 {
  width: 188px;
}

.h-188 {
  height: 188px;
}

.maxw-188 {
  max-width: 188px;
}

.width-xs-188 {
  width: 188px;
}

.height-xs-188 {
  height: 188px;
}

.maxw-xs-188 {
  max-width: 188px;
}

@media screen and (min-width: 312px) {
  .width-sm-188 {
    width: 188px;
  }
  .height-sm-188 {
    height: 188px;
  }
  .maxw-sm-188 {
    max-width: 188px;
  }
}
@media screen and (min-width: 767px) {
  .width-md-188 {
    width: 188px;
  }
  .height-md-188 {
    height: 188px;
  }
  .maxw-md-188 {
    max-width: 188px;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-188 {
    width: 188px;
  }
  .height-lg-188 {
    height: 188px;
  }
  .maxw-lg-188 {
    max-width: 188px;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-188 {
    width: 188px;
  }
  .height-xl-188 {
    height: 188px;
  }
  .maxw-xl-188 {
    max-width: 188px;
  }
}
.w-98per {
  width: 98%;
}

.h-98per {
  height: 98%;
}

.maxw-98per {
  max-width: 98%;
}

.width-xs-98per {
  width: 98%;
}

.height-xs-98per {
  height: 98%;
}

.maxw-xs-98per {
  max-width: 98%;
}

@media screen and (min-width: 312px) {
  .width-sm-98per {
    width: 98%;
  }
  .height-sm-98per {
    height: 98%;
  }
  .maxw-sm-98per {
    max-width: 98%;
  }
}
@media screen and (min-width: 767px) {
  .width-md-98per {
    width: 98%;
  }
  .height-md-98per {
    height: 98%;
  }
  .maxw-md-98per {
    max-width: 98%;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-98per {
    width: 98%;
  }
  .height-lg-98per {
    height: 98%;
  }
  .maxw-lg-98per {
    max-width: 98%;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-98per {
    width: 98%;
  }
  .height-xl-98per {
    height: 98%;
  }
  .maxw-xl-98per {
    max-width: 98%;
  }
}
.width-xs-btn {
  width: 126px !important;
}

@media screen and (min-width: 312px) {
  .width-sm-btn {
    width: 126px !important;
  }
}
@media screen and (min-width: 767px) {
  .width-md-btn {
    width: 126px !important;
  }
}
@media screen and (min-width: 992px) {
  .width-lg-btn {
    width: 126px !important;
  }
}
@media screen and (min-width: 1440px) {
  .width-xl-btn {
    width: 126px !important;
  }
}
html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

body {
  overflow-x: hidden;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  max-width: 100%;
}

p {
  letter-spacing: 0.7px;
  line-height: 28px;
}

@media (min-width: 1440px) {
  p {
    line-height: 34px;
  }
  p.description {
    line-height: 18px;
  }
}
.weblink, .figmalink, .talklink {
  width: 91px;
  display: inline-block;
  height: 35px;
  text-align: center;
  line-height: 2.4;
  margin-right: 20px;
}

.talklink {
  width: 117px;
  margin-top: 40px;
}

.wrap {
  flex-wrap: wrap;
}

.no-wrap {
  flex-wrap: no-wrap;
}

.justify-center {
  justify-content: center;
}

.justify-xs-center {
  justify-content: center !important;
}

@media screen and (min-width: 312px) {
  .justify-sm-center {
    justify-content: center !important;
  }
}
@media screen and (min-width: 767px) {
  .justify-md-center {
    justify-content: center !important;
  }
}
@media screen and (min-width: 992px) {
  .justify-lg-center {
    justify-content: center !important;
  }
}
@media screen and (min-width: 1440px) {
  .justify-xl-center {
    justify-content: center !important;
  }
}
.justify-end {
  justify-content: end;
}

.justify-xs-end {
  justify-content: end !important;
}

@media screen and (min-width: 312px) {
  .justify-sm-end {
    justify-content: end !important;
  }
}
@media screen and (min-width: 767px) {
  .justify-md-end {
    justify-content: end !important;
  }
}
@media screen and (min-width: 992px) {
  .justify-lg-end {
    justify-content: end !important;
  }
}
@media screen and (min-width: 1440px) {
  .justify-xl-end {
    justify-content: end !important;
  }
}
.justify-start {
  justify-content: start;
}

.justify-xs-start {
  justify-content: start !important;
}

@media screen and (min-width: 312px) {
  .justify-sm-start {
    justify-content: start !important;
  }
}
@media screen and (min-width: 767px) {
  .justify-md-start {
    justify-content: start !important;
  }
}
@media screen and (min-width: 992px) {
  .justify-lg-start {
    justify-content: start !important;
  }
}
@media screen and (min-width: 1440px) {
  .justify-xl-start {
    justify-content: start !important;
  }
}
.justify-space-between {
  justify-content: space-between;
}

.justify-xs-space-between {
  justify-content: space-between !important;
}

@media screen and (min-width: 312px) {
  .justify-sm-space-between {
    justify-content: space-between !important;
  }
}
@media screen and (min-width: 767px) {
  .justify-md-space-between {
    justify-content: space-between !important;
  }
}
@media screen and (min-width: 992px) {
  .justify-lg-space-between {
    justify-content: space-between !important;
  }
}
@media screen and (min-width: 1440px) {
  .justify-xl-space-between {
    justify-content: space-between !important;
  }
}
.justify-space-around {
  justify-content: space-around;
}

.justify-xs-space-around {
  justify-content: space-around !important;
}

@media screen and (min-width: 312px) {
  .justify-sm-space-around {
    justify-content: space-around !important;
  }
}
@media screen and (min-width: 767px) {
  .justify-md-space-around {
    justify-content: space-around !important;
  }
}
@media screen and (min-width: 992px) {
  .justify-lg-space-around {
    justify-content: space-around !important;
  }
}
@media screen and (min-width: 1440px) {
  .justify-xl-space-around {
    justify-content: space-around !important;
  }
}
.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-xs-space-evenly {
  justify-content: space-evenly !important;
}

@media screen and (min-width: 312px) {
  .justify-sm-space-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 767px) {
  .justify-md-space-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 992px) {
  .justify-lg-space-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 1440px) {
  .justify-xl-space-evenly {
    justify-content: space-evenly !important;
  }
}
.align-start {
  align-items: start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.direction-column {
  flex-direction: column;
}

.direction-row {
  flex-direction: row;
}

.direction-xs-col {
  flex-direction: column !important;
}

.direction-xs-row {
  flex-direction: row !important;
}

@media screen and (min-width: 312px) {
  .direction-sm-col {
    flex-direction: column !important;
  }
  .direction-sm-row {
    flex-direction: row !important;
  }
}
@media screen and (min-width: 767px) {
  .direction-md-col {
    flex-direction: column !important;
  }
  .direction-md-row {
    flex-direction: row !important;
  }
}
@media screen and (min-width: 992px) {
  .direction-lg-col {
    flex-direction: column !important;
  }
  .direction-lg-row {
    flex-direction: row !important;
  }
}
@media screen and (min-width: 1440px) {
  .direction-xl-col {
    flex-direction: column !important;
  }
  .direction-xl-row {
    flex-direction: row !important;
  }
}
.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: 70px;
    padding-right: 70px;
  }
}
.container__col-offset-0 {
  margin-left: 0;
}
.container__col-1 {
  flex-basis: 8.3333333333%;
}
.container__col-offset-1 {
  margin-left: 8.3333333333%;
}
.container__col-2 {
  flex-basis: 16.6666666667%;
}
.container__col-offset-2 {
  margin-left: 16.6666666667%;
}
.container__col-3 {
  flex-basis: 25%;
}
.container__col-offset-3 {
  margin-left: 25%;
}
.container__col-4 {
  flex-basis: 33.3333333333%;
}
.container__col-offset-4 {
  margin-left: 33.3333333333%;
}
.container__col-5 {
  flex-basis: 41.6666666667%;
}
.container__col-offset-5 {
  margin-left: 41.6666666667%;
}
.container__col-6 {
  flex-basis: 50%;
}
.container__col-offset-6 {
  margin-left: 50%;
}
.container__col-7 {
  flex-basis: 58.3333333333%;
}
.container__col-offset-7 {
  margin-left: 58.3333333333%;
}
.container__col-8 {
  flex-basis: 66.6666666667%;
}
.container__col-offset-8 {
  margin-left: 66.6666666667%;
}
.container__col-9 {
  flex-basis: 75%;
}
.container__col-offset-9 {
  margin-left: 75%;
}
.container__col-10 {
  flex-basis: 83.3333333333%;
}
.container__col-offset-10 {
  margin-left: 83.3333333333%;
}
.container__col-11 {
  flex-basis: 91.6666666667%;
}
.container__col-offset-11 {
  margin-left: 91.6666666667%;
}
.container__col-12 {
  flex-basis: 100%;
}
.container__col-offset-12 {
  margin-left: 100%;
}
@media screen and (min-width: 312px) {
  .container__col-sm-offset-0 {
    margin-left: 0;
  }
  .container__col-sm-1 {
    flex-basis: 8.3333333333%;
  }
  .container__col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .container__col-sm-2 {
    flex-basis: 16.6666666667%;
  }
  .container__col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .container__col-sm-3 {
    flex-basis: 25%;
  }
  .container__col-sm-offset-3 {
    margin-left: 25%;
  }
  .container__col-sm-4 {
    flex-basis: 33.3333333333%;
  }
  .container__col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .container__col-sm-5 {
    flex-basis: 41.6666666667%;
  }
  .container__col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .container__col-sm-6 {
    flex-basis: 50%;
  }
  .container__col-sm-offset-6 {
    margin-left: 50%;
  }
  .container__col-sm-7 {
    flex-basis: 58.3333333333%;
  }
  .container__col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .container__col-sm-8 {
    flex-basis: 66.6666666667%;
  }
  .container__col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .container__col-sm-9 {
    flex-basis: 75%;
  }
  .container__col-sm-offset-9 {
    margin-left: 75%;
  }
  .container__col-sm-10 {
    flex-basis: 83.3333333333%;
  }
  .container__col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .container__col-sm-11 {
    flex-basis: 91.6666666667%;
  }
  .container__col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .container__col-sm-12 {
    flex-basis: 100%;
  }
  .container__col-sm-offset-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 767px) {
  .container__col-md-offset-0 {
    margin-left: 0;
  }
  .container__col-md-1 {
    flex-basis: 8.3333333333%;
  }
  .container__col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .container__col-md-2 {
    flex-basis: 16.6666666667%;
  }
  .container__col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .container__col-md-3 {
    flex-basis: 25%;
  }
  .container__col-md-offset-3 {
    margin-left: 25%;
  }
  .container__col-md-4 {
    flex-basis: 33.3333333333%;
  }
  .container__col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .container__col-md-5 {
    flex-basis: 41.6666666667%;
  }
  .container__col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .container__col-md-6 {
    flex-basis: 50%;
  }
  .container__col-md-offset-6 {
    margin-left: 50%;
  }
  .container__col-md-7 {
    flex-basis: 58.3333333333%;
  }
  .container__col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .container__col-md-8 {
    flex-basis: 66.6666666667%;
  }
  .container__col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .container__col-md-9 {
    flex-basis: 75%;
  }
  .container__col-md-offset-9 {
    margin-left: 75%;
  }
  .container__col-md-10 {
    flex-basis: 83.3333333333%;
  }
  .container__col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .container__col-md-11 {
    flex-basis: 91.6666666667%;
  }
  .container__col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .container__col-md-12 {
    flex-basis: 100%;
  }
  .container__col-md-offset-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 992px) {
  .container__col-lg-offset-0 {
    margin-left: 0;
  }
  .container__col-lg-1 {
    flex-basis: 8.3333333333%;
  }
  .container__col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .container__col-lg-2 {
    flex-basis: 16.6666666667%;
  }
  .container__col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .container__col-lg-3 {
    flex-basis: 25%;
  }
  .container__col-lg-offset-3 {
    margin-left: 25%;
  }
  .container__col-lg-4 {
    flex-basis: 33.3333333333%;
  }
  .container__col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .container__col-lg-5 {
    flex-basis: 41.6666666667%;
  }
  .container__col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .container__col-lg-6 {
    flex-basis: 50%;
  }
  .container__col-lg-offset-6 {
    margin-left: 50%;
  }
  .container__col-lg-7 {
    flex-basis: 58.3333333333%;
  }
  .container__col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .container__col-lg-8 {
    flex-basis: 66.6666666667%;
  }
  .container__col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .container__col-lg-9 {
    flex-basis: 75%;
  }
  .container__col-lg-offset-9 {
    margin-left: 75%;
  }
  .container__col-lg-10 {
    flex-basis: 83.3333333333%;
  }
  .container__col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .container__col-lg-11 {
    flex-basis: 91.6666666667%;
  }
  .container__col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .container__col-lg-12 {
    flex-basis: 100%;
  }
  .container__col-lg-offset-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1440px) {
  .container__col-xl-offset-0 {
    margin-left: 0;
  }
  .container__col-xl-1 {
    flex-basis: 8.3333333333%;
  }
  .container__col-xl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .container__col-xl-2 {
    flex-basis: 16.6666666667%;
  }
  .container__col-xl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .container__col-xl-3 {
    flex-basis: 25%;
  }
  .container__col-xl-offset-3 {
    margin-left: 25%;
  }
  .container__col-xl-4 {
    flex-basis: 33.3333333333%;
  }
  .container__col-xl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .container__col-xl-5 {
    flex-basis: 41.6666666667%;
  }
  .container__col-xl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .container__col-xl-6 {
    flex-basis: 50%;
  }
  .container__col-xl-offset-6 {
    margin-left: 50%;
  }
  .container__col-xl-7 {
    flex-basis: 58.3333333333%;
  }
  .container__col-xl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .container__col-xl-8 {
    flex-basis: 66.6666666667%;
  }
  .container__col-xl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .container__col-xl-9 {
    flex-basis: 75%;
  }
  .container__col-xl-offset-9 {
    margin-left: 75%;
  }
  .container__col-xl-10 {
    flex-basis: 83.3333333333%;
  }
  .container__col-xl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .container__col-xl-11 {
    flex-basis: 91.6666666667%;
  }
  .container__col-xl-offset-11 {
    margin-left: 91.6666666667%;
  }
  .container__col-xl-12 {
    flex-basis: 100%;
  }
  .container__col-xl-offset-12 {
    margin-left: 100%;
  }
}

/**** Colors *****/
.color-primary-black {
  color: #000;
}

.color-secondary-black {
  color: #14142B;
}

.color-black-aplha-6 {
  color: rgba(0, 0, 0, 0.6);
}

.color-black-aplha-5 {
  color: rgba(0, 0, 0, 0.5);
}

.color-blue {
  color: #3E0EFF;
}

.color-red {
  color: #EE0C0C;
}

.color-gray {
  color: #D9D9D9;
}

body {
  font-family: "Indie Flower", cursive;
}

.font-size-h1 {
  font-size: clamp(34px, 5.7vw, 68px);
}

.font-size-xs-h1 {
  font-size: clamp(34px, 5.7vw, 68px) !important;
}

@media screen and (max-width: 312px) {
  .font-size-sm-h1 {
    font-size: clamp(34px, 5.7vw, 68px) !important;
  }
}
@media screen and (max-width: 767px) {
  .font-size-md-h1 {
    font-size: clamp(34px, 5.7vw, 68px) !important;
  }
}
@media screen and (max-width: 992px) {
  .font-size-lg-h1 {
    font-size: clamp(34px, 5.7vw, 68px) !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-size-xl-h1 {
    font-size: clamp(34px, 5.7vw, 68px) !important;
  }
}
.font-size-h2 {
  font-size: clamp(34px, -0.875rem + 8.333vw, 42px);
}

.font-size-xs-h2 {
  font-size: clamp(34px, -0.875rem + 8.333vw, 42px) !important;
}

@media screen and (max-width: 312px) {
  .font-size-sm-h2 {
    font-size: clamp(34px, -0.875rem + 8.333vw, 42px) !important;
  }
}
@media screen and (max-width: 767px) {
  .font-size-md-h2 {
    font-size: clamp(34px, -0.875rem + 8.333vw, 42px) !important;
  }
}
@media screen and (max-width: 992px) {
  .font-size-lg-h2 {
    font-size: clamp(34px, -0.875rem + 8.333vw, 42px) !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-size-xl-h2 {
    font-size: clamp(34px, -0.875rem + 8.333vw, 42px) !important;
  }
}
.font-size-h3 {
  font-size: clamp(18px, 2.5vw, 42px);
}

.font-size-xs-h3 {
  font-size: clamp(18px, 2.5vw, 42px) !important;
}

@media screen and (max-width: 312px) {
  .font-size-sm-h3 {
    font-size: clamp(18px, 2.5vw, 42px) !important;
  }
}
@media screen and (max-width: 767px) {
  .font-size-md-h3 {
    font-size: clamp(18px, 2.5vw, 42px) !important;
  }
}
@media screen and (max-width: 992px) {
  .font-size-lg-h3 {
    font-size: clamp(18px, 2.5vw, 42px) !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-size-xl-h3 {
    font-size: clamp(18px, 2.5vw, 42px) !important;
  }
}
.font-size-h4 {
  font-size: clamp(18px, -0.875rem + 8.333vw, 24px);
}

.font-size-xs-h4 {
  font-size: clamp(18px, -0.875rem + 8.333vw, 24px) !important;
}

@media screen and (max-width: 312px) {
  .font-size-sm-h4 {
    font-size: clamp(18px, -0.875rem + 8.333vw, 24px) !important;
  }
}
@media screen and (max-width: 767px) {
  .font-size-md-h4 {
    font-size: clamp(18px, -0.875rem + 8.333vw, 24px) !important;
  }
}
@media screen and (max-width: 992px) {
  .font-size-lg-h4 {
    font-size: clamp(18px, -0.875rem + 8.333vw, 24px) !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-size-xl-h4 {
    font-size: clamp(18px, -0.875rem + 8.333vw, 24px) !important;
  }
}
.font-size-paragraph {
  font-size: clamp(16px, 2.5vw, 18px);
}

.font-size-xs-paragraph {
  font-size: clamp(16px, 2.5vw, 18px) !important;
}

@media screen and (max-width: 312px) {
  .font-size-sm-paragraph {
    font-size: clamp(16px, 2.5vw, 18px) !important;
  }
}
@media screen and (max-width: 767px) {
  .font-size-md-paragraph {
    font-size: clamp(16px, 2.5vw, 18px) !important;
  }
}
@media screen and (max-width: 992px) {
  .font-size-lg-paragraph {
    font-size: clamp(16px, 2.5vw, 18px) !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-size-xl-paragraph {
    font-size: clamp(16px, 2.5vw, 18px) !important;
  }
}
.font-size-span {
  font-size: clamp(13px, 0.5vw, 18px);
}

.font-size-xs-span {
  font-size: clamp(13px, 0.5vw, 18px) !important;
}

@media screen and (max-width: 312px) {
  .font-size-sm-span {
    font-size: clamp(13px, 0.5vw, 18px) !important;
  }
}
@media screen and (max-width: 767px) {
  .font-size-md-span {
    font-size: clamp(13px, 0.5vw, 18px) !important;
  }
}
@media screen and (max-width: 992px) {
  .font-size-lg-span {
    font-size: clamp(13px, 0.5vw, 18px) !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-size-xl-span {
    font-size: clamp(13px, 0.5vw, 18px) !important;
  }
}
.font-size-14 {
  font-size: 14px;
}

.font-size-xs-14 {
  font-size: 14px !important;
}

@media screen and (max-width: 312px) {
  .font-size-sm-14 {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 767px) {
  .font-size-md-14 {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 992px) {
  .font-size-lg-14 {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-size-xl-14 {
    font-size: 14px !important;
  }
}
.font-size-15 {
  font-size: 0.8em;
}

.font-size-xs-15 {
  font-size: 0.8em !important;
}

@media screen and (max-width: 312px) {
  .font-size-sm-15 {
    font-size: 0.8em !important;
  }
}
@media screen and (max-width: 767px) {
  .font-size-md-15 {
    font-size: 0.8em !important;
  }
}
@media screen and (max-width: 992px) {
  .font-size-lg-15 {
    font-size: 0.8em !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-size-xl-15 {
    font-size: 0.8em !important;
  }
}
.font-size-18 {
  font-size: 18px;
}

.font-size-xs-18 {
  font-size: 18px !important;
}

@media screen and (max-width: 312px) {
  .font-size-sm-18 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 767px) {
  .font-size-md-18 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 992px) {
  .font-size-lg-18 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-size-xl-18 {
    font-size: 18px !important;
  }
}
.font-size-1_5 {
  font-size: clamp(12px, 2.5vw, 25px);
}

.font-size-xs-1_5 {
  font-size: clamp(12px, 2.5vw, 25px) !important;
}

@media screen and (max-width: 312px) {
  .font-size-sm-1_5 {
    font-size: clamp(12px, 2.5vw, 25px) !important;
  }
}
@media screen and (max-width: 767px) {
  .font-size-md-1_5 {
    font-size: clamp(12px, 2.5vw, 25px) !important;
  }
}
@media screen and (max-width: 992px) {
  .font-size-lg-1_5 {
    font-size: clamp(12px, 2.5vw, 25px) !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-size-xl-1_5 {
    font-size: clamp(12px, 2.5vw, 25px) !important;
  }
}
.font-size-1 {
  font-size: 1em;
}

.font-size-xs-1 {
  font-size: 1em !important;
}

@media screen and (max-width: 312px) {
  .font-size-sm-1 {
    font-size: 1em !important;
  }
}
@media screen and (max-width: 767px) {
  .font-size-md-1 {
    font-size: 1em !important;
  }
}
@media screen and (max-width: 992px) {
  .font-size-lg-1 {
    font-size: 1em !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-size-xl-1 {
    font-size: 1em !important;
  }
}
.text-center {
  text-align: center;
}

.text-xs-center {
  text-align: center !important;
}

@media screen and (min-width: 312px) {
  .text-sm-center {
    text-align: center !important;
  }
}
@media screen and (min-width: 767px) {
  .text-md-center {
    text-align: center !important;
  }
}
@media screen and (min-width: 992px) {
  .text-lg-center {
    text-align: center !important;
  }
}
@media screen and (min-width: 1440px) {
  .text-xl-center {
    text-align: center !important;
  }
}
.text-left {
  text-align: left;
}

.text-xs-left {
  text-align: left !important;
}

@media screen and (min-width: 312px) {
  .text-sm-left {
    text-align: left !important;
  }
}
@media screen and (min-width: 767px) {
  .text-md-left {
    text-align: left !important;
  }
}
@media screen and (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
}
@media screen and (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important;
  }
}
.text-right {
  text-align: right;
}

.text-xs-right {
  text-align: right !important;
}

@media screen and (min-width: 312px) {
  .text-sm-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 767px) {
  .text-md-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 992px) {
  .text-lg-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 1440px) {
  .text-xl-right {
    text-align: right !important;
  }
}
.bg-stickyImg {
  background: url("../assets/stickyImg.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-hamburgerImg {
  background: url("../assets/hamburgerImg.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-cerrarImg {
  background: url("../assets/cerrarImg.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-bordeMenu {
  background: url("../assets/bordeMenu.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-hero_btn {
  background: url("../assets/hero_btn.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-sticky_green {
  background: url("../assets/sticky_green.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-sticky_blue {
  background: url("../assets/sticky_blue.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-sticky_yellow {
  background: url("../assets/sticky_yellow.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-btn_download {
  background: url("../assets/btn_download.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-frame1 {
  background: url("../assets/frame1.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-frame2 {
  background: url("../assets/frame2.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-frame3 {
  background: url("../assets/frame3.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-frame4 {
  background: url("../assets/frame4.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-web_btn {
  background: url("../assets/web_btn.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-figma_btn {
  background: url("../assets/figma_btn.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-talk_btn {
  background: url("../assets/talk_btn.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
.bg-input_line {
  background: url("../assets/input_line.svg") no-repeat;
  background-size: cover;
}

.bg-xs-contain {
  background-size: contain !important;
}

@media screen and (min-width: 312px) {
  .bg-sm-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 767px) {
  .bg-md-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .bg-lg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1440px) {
  .bg-xl-contain {
    background-size: contain !important;
  }
}
/*** Vendors ***/
.textWithArrow_container {
  display: none;
}

@media screen and (min-width: 879px) {
  .textWithArrow_container {
    display: flex;
  }
  .picture img {
    order: 1;
    margin-left: 5px;
    margin-top: 10px;
  }
}
.sticky {
  display: none;
}

@media screen and (min-width: 744px) {
  section .container .sticky {
    display: block;
    background-size: contain;
    height: 140px;
    width: 127px;
    right: 0;
    text-align: center;
    top: 0;
  }
  section .container .sticky p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 18px;
    transform: rotate(-2.516deg);
    margin-top: 40px;
  }
  section .container .sticky div {
    width: 50%;
  }
}
@media screen and (min-width: 1440px) {
  section .container .sticky {
    height: 400px;
    width: 200px;
    top: 50px;
  }
  section .container .sticky p {
    font-size: 24px;
  }
}
button {
  background: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  border: none;
  -webkit-user-select: none; /* for button */
  -webkit-appearance: button; /* for input */
  -moz-user-select: none;
  -ms-user-select: none;
}

.hamburger {
  height: 25px;
  width: 25px;
}

.close {
  background: url("./../assets/cerrarImg.svg") no-repeat;
}

.skills_list::before {
  background-color: gray;
  border-radius: 50%;
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 15px;
}

.sticky_skills {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
}

@media (max-width: 678px) {
  .sticky_kill {
    order: 1;
  }
}
@media (min-width: 678px) {
  .sticky_skills p {
    line-height: normal;
    font-size: 15px;
  }
}
.card {
  background-size: contain;
}
.card .card_img {
  z-index: -1;
}

form label {
  color: rgba(0, 0, 0, 0.5);
}

form input,
form textarea {
  background-position: bottom !important;
  background-size: contain !important;
  border: none;
  margin-bottom: 20px;
}
@media (min-width: 1440px) {
  form input,
  form textarea {
    margin-bottom: 40px;
  }
}

form input {
  height: 30px;
}
@media (min-width: 1440px) {
  form input {
    margin-bottom: 30px;
  }
}

form textarea {
  height: 135px;
  resize: none;
}
@media (max-width: 768px) {
  form textarea {
    height: 60px;
  }
}

form button {
  background-size: contain !important;
  height: 60px;
  width: 160px;
  display: inline-block;
  text-align: center;
  line-height: 2.5;
}

.message {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 30px;
  text-align: center;
}

nav {
  display: flex;
}
nav > ul {
  display: none;
}
nav > ul > a {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  padding: 15px 10px;
}

header .textWithArrow_container {
  display: none;
}

nav ul a .icon {
  margin: 15px 10px;
}

nav .line {
  order: 1;
  margin-bottom: 10px;
}

.hamburger#open {
  background: url("../assets/cerrarImg.svg") no-repeat;
}

@media screen and (max-width: 997px) {
  .hamburger#open + ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: auto;
    width: 90%;
    top: 100px;
    left: 0;
    background-color: #D9D9D9;
    transform: translateX(5%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.6);
    padding: 40px;
    z-index: 999;
  }
}
@media screen and (min-width: 997px) {
  header {
    background: url(../assets/bordeMenu.svg) no-repeat bottom/contain;
  }
  header .textWithArrow_container {
    display: flex;
  }
  nav {
    position: relative;
  }
  .hamburger#open + ul,
  nav ul {
    align-items: center;
    box-shadow: unset;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    height: auto;
    position: relative;
    top: 0;
    left: unset;
    width: unset;
    padding: unset;
  }
  .hamburger#open + ul span,
  nav ul span {
    position: absolute;
    left: 52%;
    bottom: -20%;
  }
  nav > ul > a {
    margin: 0 10px;
    position: relative;
    text-align: center;
    width: 100px;
    padding: unset;
  }
  nav > ul > a::after {
    content: "";
    background: url("../assets/separador_link.png") no-repeat;
    height: 32.5px;
    position: absolute;
    right: -14px;
    top: -3px;
    width: 8.6px;
  }
  nav > ul > a:last-of-type::after {
    background: none;
  }
  nav > ul > a::before {
    content: "";
    background-color: var(--gray);
    width: 100%;
    height: 18px;
    position: absolute;
    top: 3px;
    z-index: -1;
    left: 0;
  }
  .hamburger {
    display: none;
  }
  ul .textWithArrow_container.social .line {
    position: absolute;
    right: 15%;
    top: 65%;
  }
  .navArrow {
    position: absolute;
    top: 42%;
    left: -150px;
  }
  .navArrow span {
    top: 5px;
    position: relative;
    right: 5px;
  }
}
footer {
  background: url(../assets/separador_footer.svg) no-repeat top/contain;
}

.footer_bottom .container {
  padding: 10px;
}

.footer_inner li {
  padding: 6px 0;
}

.footer_inner .icon {
  margin: 10px 10px 10px 0;
}

.footer_bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.developer_name {
  font-size: 19px;
}

@media screen and (min-width: 768px) {
  footer .container {
    display: flex;
    justify-content: space-between;
  }
  .footer_inner:nth-child(1) {
    max-width: 380px;
  }
}