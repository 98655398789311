$box-sizes: (
  'auto': auto,
  '3': 3px,
  '5' : 5px,
  '20': 20px,
  '25': 25px,
  '30': 30px,
  '35': 35px,
  '40': 40px,
  '70': 70px,
  '60': 60px,
  '50': 50px,
  '100': 100px,
  '140': 140px,
  '200': 200px,
  '270': 270px,
  '10': 10px,
  'm120': -120px,
  'm50': -50px,
  'm10': -10px,
  '0': 0,
);

@each $padding,
$padding-code in $box-sizes {
  .p-#{$padding} {
    padding: $padding-code;
  }

  .p-x-#{$padding} {
    padding-left: $padding-code;
    padding-right: $padding-code;
  }

  .p-y-#{$padding} {
    padding-top: $padding-code;
    padding-bottom: $padding-code;
  }

  @each $modifier ,
  $breakpoint in $map-viewports {
    @include create-mq($breakpoint, 'min') {
      .padding#{$modifier}-#{$padding} {
        padding: $padding-code !important;
      }
      .padding-y#{$modifier}-#{$padding} {
        padding-top: $padding-code !important;
        padding-bottom: $padding-code !important;
      }
      .padding-x#{$modifier}-#{$padding} {
        padding-left: $padding-code !important;
        padding-right: $padding-code !important;
      }
    }
  }
}

@each $margin,
$margin-code in $box-sizes {
  .m-#{$margin} {
    margin: $margin-code;
  }

  .m-x-#{$margin} {
    margin-left: $margin-code;
    margin-right: $margin-code;
  }

  .m-y-#{$margin} {
    margin-top: $margin-code;
    margin-bottom: $margin-code;
  }
  .m-t-#{$margin} {
    margin-top: $margin-code;
  }
  .top-#{$margin}{
    top: $margin-code;
  }
  .left-#{$margin}{
    left: $margin-code;
  }
  .right-#{$margin}{
    right: $margin-code;
  }
  @each $modifier ,
  $breakpoint in $map-viewports {
  @include create-mq($breakpoint, 'min') {
    .m#{$modifier}-#{$margin} {
      margin: $margin-code !important;
    }
    .m-t#{$modifier}-#{$margin} {
      margin-top: $margin-code !important;
    }
    .m-b#{$modifier}-#{$margin} {
      margin-bottom: $margin-code !important;
    }
    .top#{$modifier}-#{$margin}{
      top: $margin-code !important;
    }
  }
  }
}

$positions: relative, absolute;

@each $position in $positions {
  .position-#{$position} {
    position: $position;
  }
}

$displays: block flex inline-block none;

@each $display in $displays {
  .d-#{$display} {
    display: $display;
  }
}

$sizes: (
  '37': 37px,
  '100': 100px,
  '185': 185px,
  '243': 243px,
  '248': 248px,
  '263': 263px,
  '257': 257px,
  '271': 271px,
  '350': 350px,
  '400': 400px,
  '478': 478px,
  '629': 629px,
  '664': 664px,
  '676': 676px,
  '50per': 50%,
  '181': 181px,
  '188': 188px,
  '98per': 98%
);

@each $size,
$size-code in $sizes {
  .w-#{$size} {
    width: $size-code;
  }

  .h-#{$size} {
    height: $size-code;
  }
  .maxw-#{$size} {
    max-width: $size-code;
  }
  @each $modifier ,
$breakpoint in $map-viewports {
  @if($modifier =='') {
    $modifier: '-xs';
  }

  @include create-mq($breakpoint, 'min') {
    .width#{$modifier}-#{$size} {
      width: $size-code;
    }
    .height#{$modifier}-#{$size} {
      height: $size-code;
    }
    .maxw#{$modifier}-#{$size} {
      max-width: $size-code;
    }
  }

}
}

@each $modifier ,
$breakpoint in $map-viewports {
  @if($modifier =='') {
    $modifier: '-xs';
  }

  @include create-mq($breakpoint, 'min') {
    .width#{$modifier}-btn {
      width: 126px !important;
    }
  }

}