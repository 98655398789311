.textWithArrow_container {
    display: none;
}
@media screen and (min-width: 879px){
    .textWithArrow_container {
        display: flex;
    }
    .picture img{
        order:1;
        margin-left: 5px;
        margin-top: 10px;
    }
}