button{
    background: none; 
    color: inherit; 
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    border: none;
    -webkit-user-select: none; /* for button */
    -webkit-appearance: button; /* for input */
      -moz-user-select: none;
       -ms-user-select: none;
}
.hamburger{ 
    height: 25px;
    width: 25px;
}
.close{
    background: url('./../assets/cerrarImg.svg') no-repeat;
}

