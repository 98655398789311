header .container{
    align-items: center;
    display: flex;
    justify-content: space-between; 
    position: relative;
}
@media (max-width: 412px) {
    header .container{
        padding: 40px 20px;
}
}