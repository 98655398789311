.skills_list::before {
    background-color: gray;
    border-radius: 50%;
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 15px;
}

.sticky_skills {
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
}

@media (max-width: 678px) {
    .sticky_kill {
        order: 1;
    }
}

@media (min-width: 678px) {
    .sticky_skills {

        p {
            line-height: normal;
            font-size: 15px;
        }
    }
}