nav {
    display: flex;

    >ul {
        display: none;

        >a {
            display: inline-block;
            font-size: 18px;
            font-weight: 400;
            padding: 15px 10px;
        }
    }
}

header .textWithArrow_container {
    display: none;
}

nav ul a .icon {
    margin: 15px 10px;
}

nav .line {
    order: 1;
    margin-bottom: 10px;
}

.hamburger#open {
    background: url('../assets/cerrarImg.svg') no-repeat;
}
@media screen and (max-width: 997px) {
.hamburger#open+ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: auto;
    width: 90%;
    top: 100px;
    left: 0;
    background-color: #D9D9D9;
    transform: translateX(5%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .6);
    padding: 40px;
    z-index: 999;
}
}
@media screen and (min-width: 997px) {
    header {
        background: url(../assets/bordeMenu.svg) no-repeat bottom/contain;
    }

    header .textWithArrow_container {
        display: flex;
    }

    nav {
        position: relative;
    }

    .hamburger#open+ul,
    nav ul {
        align-items: center;
        box-shadow: unset;
        display: flex;
        flex-direction: row;
        background-color: transparent;
        height: auto;
        position: relative;
        top: 0;
        left: unset;
        width: unset;
        padding: unset;

        span {
            position: absolute;
            left: 52%;
            bottom: -20%;
        }
    }

    nav>ul>a {
        margin: 0 10px;
        position: relative;
        text-align: center;
        width: 100px;
        padding: unset;
    }

    nav>ul>a::after {
        content: "";
        background: url('../assets/separador_link.png') no-repeat;
        height: 32.5px;
        position: absolute;
        right: -14px;
        top: -3px;
        width: 8.6px;
    }

    nav>ul>a:last-of-type::after {
        background: none;
    }

    nav>ul>a::before {
        content: "";
        background-color: var(--gray);
        width: 100%;
        height: 18px;
        position: absolute;
        top: 3px;
        z-index: -1;
        left: 0;
    }

    .hamburger {
        display: none;
    }

    ul .textWithArrow_container.social .line {
        position: absolute;
        right: 15%;
        top: 65%;
    }

    .navArrow {
        position: absolute;
        top: 42%;
        left: -150px;

        span {
            top: 5px;
            position: relative;
            right: 5px;
        }
    }
}