form label{
    color: rgba(0, 0, 0, 0.50);
}
form input,
form textarea {
    background-position: bottom !important;
    background-size: contain !important;
    border: none;
    margin-bottom: 20px;
    @media (min-width: 1440px) {
        margin-bottom: 40px;
    }
}

form input {
    height: 30px;
    @media (min-width: 1440px) {
        margin-bottom: 30px;
    }
}

form textarea {
    height: 135px;
    resize: none;
    @media (max-width: 768px) {
        height: 60px;
    }
}
form button{
    background-size: contain !important;
    height: 60px;
    width: 160px;
    display: inline-block;
    text-align: center;
    line-height: 2.5;
}
.message{
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-size: 30px;
    text-align: center;

}