$flex__cols: 12;

// A mixin called create-col-classes and use the create-mq mixin.
@mixin create-col-classes($modifier, $flex-cols, $breakpoint) {
    @include create-mq($breakpoint, 'min') {
        &__col#{$modifier}-offset-0 {
            margin-left: 0;
        }

        @for $i from 1 through $flex-cols {
            &__col#{$modifier}-#{$i} {
                flex-basis: calc((100 / ($flex-cols / $i)) * 1%);
            }

            &__col#{$modifier}-offset-#{$i} {
                margin-left: calc((100 / ($flex-cols / $i)) * 1%);
            }
        }
    }
}

.container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px;
@media (min-width: 1440px){
    padding-left: 70px;
    padding-right: 70px;
}
    @each $modifier ,
    $breakpoint in $map-viewports {
        @include create-col-classes($modifier, $flex__cols, $breakpoint);
    }
}