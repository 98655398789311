html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 400;
}
body{
  overflow-x: hidden;
}
li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  max-width: 100%;
}

p {
  letter-spacing: 0.7px;
  line-height: 28px;
}

@media (min-width: 1440px) {
  p {
    line-height: 34px;
  }
  p.description{
    line-height: 18px;
  }
}

.weblink, .figmalink, .talklink {
  width: 91px;
  display: inline-block;
  height: 35px;
  text-align: center;
  line-height: 2.4;
  margin-right: 20px;
}
.talklink {
  width: 117px;
  margin-top: 40px;
}