 .sticky {
     display: none;
 }

 @media screen and (min-width: 744px) {
     section .container {

         .sticky {
             display: block;
             background-size: contain;
             height: 140px;
             width: 127px;
             right: 0;
             text-align: center;
             top:0;

             p {
                 color: rgba(0, 0, 0, 0.50);
                 font-size: 18px;
                 transform: rotate(-2.516deg);
                 margin-top: 40px;
             }

             div {
                 width: 50%;
             }
         }
     }
 }
 @media screen and (min-width: 1440px) {
    section .container .sticky { 
        height: 400px;
        width: 200px;
        top: 50px;
        p{
            font-size: 24px;
        }
    }
 }