footer{
    background: url(../assets/separador_footer.svg) no-repeat top/contain;
}
.footer_bottom .container{
    padding: 10px;
}
.footer_inner li{
    padding: 6px 0;
}
.footer_inner .icon{
    margin: 10px 10px 10px 0;
}
.footer_bottom{
    border-top: 1px solid rgba(0, 0, 0, 0.20);;
}
.developer_name{
    font-size: 19px; 
    
}
@media screen and (min-width: 768px){
 
    footer .container {
        display: flex;
        justify-content: space-between;
    } 
    .footer_inner:nth-child(1){
        max-width: 380px;
    }
}