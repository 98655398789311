@import url('https://fonts.googleapis.com/css2?family=Indie+Flower:wght@400&display=swap');
$body-font: 'Indie Flower', cursive; // Google font

body {
  font-family: $body-font;
}

$font-sizes: (
  'h1': clamp(34px, 5.7vw, 68px),
  'h2': clamp(34px, -0.875rem + 8.333vw, 42px),
  'h3': clamp(18px, 2.5vw, 42px),
  'h4': clamp(18px, -0.875rem + 8.333vw, 24px),
  'paragraph': clamp(16px, 2.5vw, 18px),
  'span': clamp(13px, 0.5vw, 18px),
  '14':  14px,
  '15' : 0.8em,
  '18': 18px,
  '1_5': clamp(12px, 2.5vw, 25px),
  "1": 1em
);

@each $font-size,
$font-code in $font-sizes {
  .font-size-#{$font-size} {
    font-size: $font-code;
  }
  @each $modifier ,
  $breakpoint in $map-viewports {
    @if($modifier =='') {
      $modifier: '-xs';
    }

    @include create-mq($breakpoint, 'max') {
      .font-size#{$modifier}-#{$font-size} {
        font-size: $font-code !important;
      }
    }
  }
}

$aligns : center left right;

@each $align in $aligns {
  .text-#{$align} {
    text-align: $align;
  }
  @each $modifier ,
  $breakpoint in $map-viewports {
    @if($modifier =='') {
      $modifier: '-xs';
    }

    @include create-mq($breakpoint, 'min') {
      .text#{$modifier}-#{$align} {
         text-align: $align !important;
      }
    }
  }
}